import React from 'react';
import '../styles.scss';
import CountDown from '../../components/CountDown';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */

export default function QuestionScreen(props) {
	
	const quiz = props.quiz;
	const screen = quiz.screens.question;
	const question = props.question;
	const options = question.options;
	const countdown = props.quiz.screens.question.countdown;
	
	return (
		<Screen
			className={`question`}
			bgColor={screen.bgColor}
			mediaBg={question.mediaQuestion || screen.background}
			css={{}}
		>
			<div className={`question-text`}>
				{screen.displayProgress && `${quiz.numQuestionsAsked}/${quiz.totalQuestions}: `}
				{question.text}
			</div>
			<div className={`countdown-wrap v-${countdown.vPosition} h-${countdown.hPosition}`}>
				{quiz.status === 'running' && <CountDown key={props.timeLeft} timeLeft={props.timeLeft} secondsOnly={true} prependZero={false}/>}
			</div>
			<div className={`options`}>
				{options.map((a, i) => <div key={i} className={`option`}>
					<div className={`option-index`}>{i + 1}</div>
					<div className={`option-text`}>{a.text}</div>
				</div>)}
			</div>
		</Screen>
	)
}

QuestionScreen.propTypes = {
	question: PropTypes.object.isRequired,
	quiz: PropTypes.object.isRequired,
	timeLeft: PropTypes.number.isRequired
};