import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Message from '../components/Message';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';
import objectPath from 'object-path';

function RoundsLeaderboardPage() {
	
	const [query] = useSearchParams();
	const cssClass = query.get('cssClass') || '';
	const [state, setState] = useState({message: null});
	
	
	/**
	 * Load the scores.
	 */
	useEffect(() => {
		let active = true;
		(async () => {
			try {
				const url = new URL(`${process.env.REACT_APP_API_SERVER}/leaderboard`);
				if (query.get('team')) {
					url.searchParams.append('team', query.get('team'));
				}
				url.searchParams.append('limit', query.get('limit') || 100);
				
				const response = await fetch(url);
				const data = await response.json();
				
				if (!active) {
					return;
				} else if (data.error) {
					//return setState({message: data.error});
				}
				
				const scores = populateTeams(objectPath.get(data.players), data.teams);
				setState({teams: data.teams, scores});
				
			} catch (e) {
				console.log(e);
				return setState({message: 'Something went wrong. Please try again later.'})
			}
		})();
		
		return () => {
			active = false;
		}
		
	}, []);
	
	const title = <><span className={'title-left'}>TOP SCORERS</span><span className={'title-right'}>Team</span></>
	return (
		<>
			<div className={`leaderboard-page rounds-leaderboard-page ${cssClass}`}>
				<div className={'redbull-logo'}></div>
				{state.message && <Message msg={state.message}/>}
				{state.scores && <ScoresScreen
					autoScroll={false}
					scores={state.scores}
					teams={state.teams}
					title={title}
					showPos={true}
					showTeam={true}
					showNewScores={false}
					scrollDelay={5}
				/>}
			</div>
		</>
	);
}

export default RoundsLeaderboardPage;