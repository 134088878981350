import React from 'react';
import io from 'socket.io-client';
import WelcomeScreen from '../common-libs/quiz/screens/WelcomeScreen';
import ResourcePreloader from '../services/resource-preloader';
import FinalGameScreen from './FinalGameScreen';
import Poster from './Poster';
import WatermarkContext from '../common-libs/context/WatermarkContext';
import ScoresScreenTeam from '../common-libs/quiz/screens/ScoresScreenTeam';
import QuestionScreen from '../common-libs/quiz/screens/QuestionScreen';
import AnswerScreen from '../common-libs/quiz/screens/AnswerScreen';
import Poll from './Poll';
import PollResultScreen from './poll/ResultScreen';

export default class Game extends React.Component {
	
	client;
	preloader;
	timeout;
	
	disableScores;
	
	/**
	 * @param props
	 * @param context
	 */
	constructor(props, context) {
		super(props, context);
		this.state = {
			quiz: props.quiz
		};
		
		this.disableScores = props.disableScores;
	}
	
	/**
	 * Connect to the quiz server.
	 */
	componentDidMount() {
		this.client = io(`${this.state.quiz.serverUrl}/?quizId=${this.state.quiz._id}`, {
			transports: ['websocket']
		});
		this.client.on('cmd', this.onMessage.bind(this));
		this.client.on('stop', () => window.location.reload());
		
		this.preloader = new ResourcePreloader(this.state.quiz);
	}
	
	/**
	 * Cleanup.
	 */
	componentWillUnmount() {
		this.client.disconnect();
		clearTimeout(this.timeout);
	}
	
	/**
	 * @param msg
	 */
	onMessage(msg) {
		console.log('quiz msg', msg);
		
		// Check if quiz is running or not.
		const quiz = {
			...this.state.quiz,
			status: msg.running ? 'running' : 'paused',
			numQuestionsAsked: msg.numQuestionsAsked,
			totalQuestions: msg.totalQuestions,
			scoreScreenType: msg.scoreScreenType || 'user'
		};
		const newState = {quiz};
		
		if (msg.action === 'screen') {
			
			let screen = msg.screen;
			if (screen === 'scores' && this.disableScores) {
				screen = 'answer';
			}

			newState.screen = screen;
			newState.date = msg.date;
			
			if (msg.till) {
				newState.till = msg.till;
			}
			
			if (screen === 'poll-question' && this.state.screen === screen) {
				this.setState(newState);
				return;
			}
			
			switch (screen) {
				case 'question':
				case 'answer':
					newState.question = msg.question;
					break;
				case 'scores':
					newState.teamResults = msg.teamResults || {};
					newState.teamGlobalResults = msg.teamGlobalResults || {};
					newState.scoresType = 'question';
					
					const now = Date.now();
					const till = +msg.till || now;
					const timePerScreen = this.state.quiz.screens.scores.duration / 2 * 1000;
					
					if (now >= till - timePerScreen) {
						newState.scoresType = 'global';
					} else {
						this.timeout = setTimeout(() => {
							this.setState(prev => {
								return {...prev, scoresType: 'global'};
							});
						}, (till - now) / 2);
					}
					break;
				case 'poll-question':
					newState.pollQuestion = msg.pollQuestion;
					break;
				case 'poll-result':
					newState.pollResult = msg.pollResult;
					break;
				case 'final':
					newState.scores = msg.results || [];
					break;
			}
		}
		this.setState(newState);
		
		console.log(this.state)
	}
	
	/**
	 * Render.
	 *
	 * @returns {JSX.Element}
	 */
	render() {
		const quiz = this.state.quiz;
		const screen = this.state.screen;
		
		return !this.state.screen ?
			<Poster quiz={quiz}/> :
			<WatermarkContext.Provider value={false}>
				{screen === 'welcome' && <WelcomeScreen
					quiz={quiz}
					timeLeft={this.state.till - this.state.date}
				/>}
				
				{screen === 'question' && <QuestionScreen
					quiz={quiz}
					timeLeft={this.state.till - this.state.date}
					question={this.state.question}
				/>}
				
				{screen === 'answer' && <AnswerScreen
					quiz={quiz}
					question={this.state.question}
				/>}
				
				{(screen === 'scores') && <ScoresScreenTeam
					teams={quiz.teams}
					scores={this.state.scoresType === 'question' ? this.state.teamResults : this.state.teamGlobalResults}
					title={this.state.scoresType === 'question' ? 'How teams scored on last question' : 'Overall Team Scores'}
				/>}
				
				{(screen === 'poll-question' && quiz.poll) && <Poll
					quiz={quiz}
					poll={quiz.poll}
					question={this.state.pollQuestion}
					timeLeft={this.state.till - this.state.date}
				/>}
				
				{(screen === 'poll-result' && quiz.poll) && <PollResultScreen
					quiz={quiz}
					question={this.state.pollResult}
				/>}
				
				{screen === 'final' && <FinalGameScreen
					quiz={quiz}
					scores={this.state.scores}/>
				}
			</WatermarkContext.Provider>
	}
}