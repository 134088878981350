import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import io from 'socket.io-client';
import Message from '../components/Message';
import './styles.scss';
import GameBlock from './GameBlock';
import SidebarBlock from './SidebarBlock';
import LeaderboardBlock from './LeaderboardBlock';

/**
 * All times and delays are in seconds.
 *
 * globalScoresScrollDelay - time to wait before start scrolling for the entire round scores. Ignored on the "scores" screen.
 * resultsScrollDelay - time to wait before start scrolling scores for that specific question.
 * resultsTime - how long to show scores for that specific question. After that time switches to the global scores.
 *
 */

const sidebarSettings = {
	'lobby': {
		'cssClass': 'top10',
		'globalScoresScrollDelay': 1,
		'resultsScrollDelay': 10,
		'resultsTime': 20,
		'showPos': false,
		'showTeam': true
	},
	'round1': {
		'cssClass': 'top10',
		'globalScoresScrollDelay': 1,
		'resultsScrollDelay': 5,
		'resultsTime': 10,
		'showPos': false,
		'showTeam': true
	},
	'round2': {
		'cssClass': 'top10',
		'globalScoresScrollDelay': 1,
		'resultsScrollDelay': 5,
		'resultsTime': 10,
		'showPos': false,
		'showTeam': true
	},
	'round3': {
		'cssClass': 'top10',
		'globalScoresScrollDelay': 1,
		'resultsScrollDelay': 5,
		'resultsTime': 10,
		'showPos': false,
		'showTeam': true
	}
};

function GamePage() {
	
	const {quizId} = useParams();
	
	const [query] = useSearchParams();
	const round = query.get('round');
	const pageType = query.get('type');
	
	const [state, setState] = useState({
		quiz: null,
		socket: null,
		message: null
	});
	
	const onCmd = cmd => console.log(cmd);
	
	useEffect(() => {
		if (!['lobby', 'round1', 'round2', 'round3'].includes(round)) {
			setState({message: 'Round is required'});
		} else if (!['game', 'leaderboard', 'g', 'l'].includes(pageType)) {
			setState({message: 'Page type is required'});
		} else {
			(async () => {
				try {
					const url = `${process.env.REACT_APP_API_SERVER}/${quizId}/leaderboard`;
					const response = await fetch(url);
					const quiz = await response.json();
					
					if (quiz.error) {
						setState({message: quiz.error});
						return;
					}
					
					const socket = io(`${quiz.serverUrl}/?quizId=${quiz._id}&leaderboard=1`, {transports: ['websocket']});
					socket.on('cmd', onCmd);
					setState({
						message: null,
						quiz,
						socket
					})
					
				} catch (e) {
					console.log(e);
					setState({message: 'Something went wrong'});
				}
			})();
			
			return () => {
				if (state && state.socket) {
					state.socket.disconnect();
				}
			}
		}
	}, [quizId]);
	
	return (
		<div className={`main-page`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{(state.quiz && ['game', 'g'].includes(pageType)) && <>
				<GameBlock
					quiz={state.quiz}
					socket={state.socket}
					disableScores={round === 'lobby'}
				/>
				<SidebarBlock
					quiz={state.quiz}
					socket={state.socket}
					round={round}
					{...sidebarSettings[round]}
				/>
			</>}
			
			{(state.quiz && ['leaderboard', 'l'].includes(pageType)) && <>
				<LeaderboardBlock
					quiz={state.quiz}
					socket={state.socket}
				/>
			</>}
		</div>
	)
}

export default GamePage;