import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import Message from '../components/Message';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';
import io from 'socket.io-client';

const objectPath = require('object-path');

function LeaderboardPage() {
	
	const {quizId} = useParams();
	let [searchParams] = useSearchParams();
	const cssClass = searchParams.get('cssClass') || '';
	
	const [v, setV] = useState(1);
	const [state, setState] = useState({message: null});
	const [screen, setScreen] = useState(null);
	
	const onCmd = cmd => {
		setScreen(cmd.screen);
	}
	
	const onLeaderboardChange = data => {
		setState(state => {
			return {...state, scores: populateTeams(data.users, state.quiz.teams)};
		});
	}
	
	useEffect(() => {
		if (screen === 'scores') {
			setV(prev => prev + 1);
		}
	}, [screen]);
	
	useEffect(() => {
		let active = true;
		let socket;
		
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/${quizId}/leaderboard`;
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (!active) {
					return;
				} else if (quiz.error) {
					return setState({message: quiz.error});
				}
				
				const scores = populateTeams(objectPath.get(quiz, 'leaderboard.topUsers'), quiz.teams);
				setState({quiz, scores});
				
				socket = io(`${quiz.serverUrl}/?quizId=${quiz._id}&leaderboard=1`, {transports: ['websocket']});
				socket.on('cmd', onCmd);
				socket.on('leaderboard:change', onLeaderboardChange);
				
			} catch (e) {
				console.log(e);
				return setState({message: 'Something went wrong. Please try again later.'})
			}
		})();
		
		return () => {
			active = false;
			if (socket) {
				socket.off('leaderboard:change', onLeaderboardChange);
				socket.disconnect();
			}
		}
	}, []);
	
	const title = <><span className={'title-left'}>TOP SCORERS</span><span className={'title-right'}>Team</span></>
	return (
		<div className={`leaderboard-page rounds-leaderboard-page ${cssClass}`}>
			<div className={'redbull-logo'}></div>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <ScoresScreen
				key={v}
				autoScroll={false}
				scores={state.scores}
				title={title}
				showPos={true}
				showTeam={true}
				showNewScores={false}
				scrollDelay={5}
				teams={state.quiz.teams || []}
			/>}
		</div>
	);
}

export default LeaderboardPage;