import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {getBackgroundImageStyles} from '../utils/Styles';

export default function Media(props) {
	const media = props.media;
	const video = (media && media.mediaType === 'video') ? media.video : null;
	const videoRef = useRef(null);
	const styles = {};
	if (media && media.mediaType === 'image') {
		styles.bgImg = media.image;
	}
	
	return (
		<div className={`${props.className} media`} style={{...getBackgroundImageStyles(styles.bgImg)}}>
			{video && <video ref={videoRef} src={video.src} autoPlay={true} onClick={() => videoRef.current.play()}/>}
		</div>
	);
}

Media.propTypes = {
	className: PropTypes.string,
	media: PropTypes.object
};

Media.defaultProps = {
	className: ''
}