import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import Game from '../components/Game';
import Message from '../components/Message';
import Poster from '../components/Poster';

function GamePage() {
	
	const {quizId} = useParams();
	
	const [query] = useSearchParams();
	const cssClass = query.get('cssClass') || '';
	
	const [quiz, setQuiz] = useState(null);
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState(null);
	
	useEffect(() => {
		if (loading) {
			setLoading(() => false);
			let to;
			
			(async () => {
				try {
					const url = `${process.env.REACT_APP_API_SERVER}/${quizId}`;
					const response = await fetch(url);
					const quiz = await response.json();
					
					if (quiz.error) {
						return setMessage(() => quiz.error);
					}
					
					setQuiz(() => quiz);
					setMessage(() => null);
					
					if (!quiz.serverUrl) {
						to = setTimeout(() => setLoading(true), 3000);
					}
					
				} catch (e) {
					console.log(e);
					setMessage('Ooops! Something went wrong');
				}
			})();
			
			return () => clearTimeout(to);
		}
	}, [loading, quizId]);
	
	return (
		<div className={`game-page ${cssClass}`}>
			{message && <Message msg={message}/>}
			{(quiz && quiz.customCss) && <style>{quiz.customCss}</style>}
			{(quiz && quiz.serverUrl) && <Game
				quiz={quiz}
				disableScores={!!query.get('disableScores')}
			/>}
		</div>
	)
}

export default GamePage;