import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import Message from '../components/Message';
import TeamPlayersScreen from '../common-libs/quiz/screens/TeamPlayersScreen';
import io from 'socket.io-client';

function TeamPlayersPage() {
	
	const {quizId} = useParams();
	
	const [query] = useSearchParams();
	const cssClass = query.get('cssClass') || '';
	
	const [state, setState] = useState({message: null});
	
	const onTeamsChange = data => {
		setState(state => {
			if (state.teams) {
				const teams = state.teams.map(t => {
					t.stats = data.teams[t._id] || {users: 0};
					return t;
				});
				return {...state, teams};
			}
			return state;
		});
	};
	
	/**
	 * Load the scores.
	 */
	useEffect(() => {
		let active = true;
		let socket;
		
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/${quizId}/teams-leaderboard`;
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (!active) {
					return;
				}
				
				if (quiz.error) {
					return setState({message: quiz.error})
				} else if (!quiz.teams || !quiz.teams.length) {
					return setState({message: 'Quiz does not have any teams'})
				}
				
				// Init socket.
				socket = io(`${quiz.serverUrl}/?quizId=${quiz._id}&leaderboard=1`, {transports: ['websocket']});
				socket.on('leaderboard:change', onTeamsChange);
				
				quiz.teams.forEach(team => {
					team.stats = quiz.leaderboard[team._id] || {users: 0}
				});
				setState({quiz, teams: quiz.teams});
				
			} catch (e) {
				console.log(e);
				return setState({message: 'Something went wrong. Please try again later.'})
			}
		})();
		
		return () => {
			active = false;
			if (socket) {
				socket.off('teams:change', onTeamsChange);
				socket.disconnect();
			}
		}
		
	}, []);
	
	return (
		<div className={`team-players-page ${cssClass}`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <TeamPlayersScreen
				teams={state.teams}
				styles={state.quiz.screens.scores.success}
			/>}
		</div>
	)
}

export default TeamPlayersPage;