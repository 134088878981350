import React, {useEffect, useState} from 'react';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';

const objectPath = require('object-path');

export default function LeaderboardBlock(props) {
	
	const quiz = props.quiz;
	const socket = props.socket;
	const cssClass = props.cssClass || '';
	
	const [state, setState] = useState({quiz, scores: []});
	
	const onLeaderboardChange = data => {
		setState(state => {
			return {...state, scores: populateTeams(data.users, state.quiz.teams)};
		});
	}
	
	useEffect(() => {
		const scores = populateTeams(objectPath.get(quiz, 'leaderboard.topUsers'), quiz.teams);
		setState({quiz, scores});
		socket.on('leaderboard:change', onLeaderboardChange);
		
		return () => {
			if (socket) {
				socket.off('leaderboard:change', onLeaderboardChange);
			}
		}
	}, []);
	
	const title = <><span className={'title-left'}>TOP SCORERS</span><span className={'title-right'}>Team</span></>
	return (
		<div className={`leaderboard-page rounds-leaderboard-page ${cssClass}`}>
			<div className={'redbull-logo'}></div>
			{state.quiz && <ScoresScreen
				autoScroll={false}
				scores={state.scores}
				title={title}
				showPos={true}
				showTeam={true}
				showNewScores={false}
				scrollDelay={5}
				teams={state.quiz.teams || []}
			/>}
		</div>
	);
}