import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import Message from '../components/Message';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';
import io from 'socket.io-client';

const objectPath = require('object-path');

function SidebarPage() {
	
	const {quizId} = useParams();
	let [searchParams] = useSearchParams();
	const cssClass = searchParams.get('cssClass') || '';
	
	// Time in seconds
	const resultsScrollDelay = +searchParams.get('resultsScrollDelay');
	const resultsTime = +searchParams.get('resultsTime');
	const globalScoresScrollDelay = +searchParams.get('globalScoresScrollDelay');
	
	const [state, setState] = useState({
		round: searchParams.get('round'),
		message: null,
		screen: null,
		quiz: null,
		questionScores: null,
		globalScores: null,
		scoresType: 'global',
		allowScroll: true,
		scrollDelay: 5000,
		timeout: null
	});
	
	const onCmd = cmd => {
		console.log(cmd);
		
		if (cmd.screen === 'poll-result') {
			return;
		}
		
		setState(prev => {
			let screen = cmd.screen;
			if (prev.round === 'round1' && screen === 'scores') {
				screen = 'question';
			} else if (screen === 'poll-question') {
				screen = 'scores';
			}
			
			const newState = {
				...prev,
				screen,
				complete: cmd.complete
			};
			if (cmd.results) {
				newState.questionScores = populateTeams(cmd.results, prev.quiz.teams);
			}
			if (cmd.globalResults) {
				newState.globalScores = populateTeams(cmd.globalResults, prev.quiz.teams);
			}
			
			if (screen === 'scores') {
				const resultsEndTime = cmd.screenDate + resultsTime * 1000;
				const dt = resultsEndTime - Date.now();
				
				if (dt > 0) {
					newState.scoresType = 'question';
					newState.allowScroll = true;
					newState.scrollDelay = resultsScrollDelay;
					
					clearTimeout(newState.timeout);
					newState.timeout = setTimeout(() => {
						setState(prev => {
							return {
								...prev,
								scoresType: 'global',
								allowScroll: false
							}
						})
					}, dt);
				} else {
					newState.scoresType = 'global';
					newState.allowScroll = false;
				}
			} else {
				newState.scoresType = 'global';
				newState.allowScroll = true;
				newState.scrollDelay = globalScoresScrollDelay;
			}
			
			return newState;
		});
	}
	
	const onLeaderboardChange = data => {
		console.log(data);
		setState(prev => {
			if (!['question', 'answer'].includes(prev.screen)) {
				return {
					...prev,
					globalScores: populateTeams(data.users, prev.quiz.teams)
				};
			}
			return prev;
		});
	}
	
	useEffect(() => {
		let active = true;
		let socket;
		
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/${quizId}/leaderboard`;
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (!active) {
					return;
				} else if (quiz.error) {
					return setState({message: quiz.error});
				}
				
				const scores = populateTeams(objectPath.get(quiz, 'leaderboard.topUsers'), quiz.teams);
				setState(prev => {
					return {
						...prev,
						quiz,
						globalScores: scores
					};
				});
				
				socket = io(`${quiz.serverUrl}/?quizId=${quiz._id}&leaderboard=1`, {transports: ['websocket']});
				socket.on('cmd', onCmd);
				socket.on('leaderboard:change', onLeaderboardChange);
				
			} catch (e) {
				console.log(e);
				return setState({message: 'Something went wrong. Please try again later.'})
			}
		})();
		
		return () => {
			active = false;
			if (socket) {
				socket.off('cmd', onCmd);
				socket.off('leaderboard:change', onLeaderboardChange);
				socket.disconnect();
			}
			
			clearTimeout(state.timeout);
		}
	}, []);
	
	console.log(state);
	
	const scores = state.scoresType === 'question' ? state.questionScores : state.globalScores;
	const title = <><span className={'title-left'}>{state.scoresType === 'question' ? 'LAST QUESTION' : 'TOP SCORERS'}</span><span className={'title-right'}>Team</span></>
	return (
		<div className={`sidebar ${cssClass}`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <ScoresScreen
				key={state.scoresType}
				autoScroll={state.allowScroll}
				scores={scores || []}
				teams={state.quiz.teams || []}
				title={title}
				showPos={state.quiz.screens.scores.success.showPos}
				showTeam={state.quiz.screens.scores.success.showTeam}
				showNewScores={state.scoresType === 'question'}
				scrollDelay={state.scrollDelay}
			/>}
		</div>
	)
}

export default SidebarPage;