import React from 'react';
import PropTypes from 'prop-types';
import Screen from '../../common-libs/quiz/components/Screen';

/** @jsxImportSource @emotion/react */

export default function PollResultScreen(props) {
	const question = props.question;
	const options = question.options || [];
	
	let totalVotes = 0;
	options.forEach(o => {
		o.votes = o.votes || 0;
		totalVotes += o.votes
	});
	
	let maxPercent = 0;
	let totalPercent = 0;
	for (let i = 0; i < options.length; i++) {
		const o = options[i];
		let votes = o.votes;
		
		if (votes > 0) {
			if (i === options.length - 1) {
				o.percent = 100 - totalPercent;
			} else {
				const percent = Math.round(o.votes / totalVotes * 100);
				o.percent = percent;
				totalPercent += percent;
			}
		} else {
			o.percent = 0;
		}
		
		maxPercent = Math.max(maxPercent, o.percent);
	}
	
	return (
		<Screen
			className={`quiz-screen answer poll`}
			css={{}}
		>
			<div className={`question-text`}>{question.text}</div>
			<div className={`options`}>
				{options.map((a, i) => <div key={i} className={`option ${(a.votes > 0 && a.percent === maxPercent) && 'correct'}`}>
					<div className={`option-index`}>{i + 1}</div>
					<div className={`option-text`}>{a.text}</div>
					<div className={'option-percent'}>{a.percent}%</div>
				</div>)}
			</div>
		</Screen>
	)
}

PollResultScreen.propTypes = {
	quiz: PropTypes.object,
	question: PropTypes.object.isRequired
}