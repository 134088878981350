import React from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */
import TeamCard from '../components/TeamCard';

export default function ScoresScreenTeam(props) {
	const title = props.title;
	const teams = props.teams || [];
	const scores = props.scores;
	
	teams.forEach(team => {
		team.stats = scores[team._id] || {totalScores: 0, correctAnswers: 0, totalAnswers: 0};
		if (team.stats.totalAnswers > 0) {
			team.stats.percent = Math.round(team.stats.correctAnswers / team.stats.totalAnswers * 100);
		} else {
			team.stats.percent = 0;
		}
	});
	
	return <Screen
		className={`scores has-scores`}
		css={{}}
	>
		<div className={`screen-title`}>
			{title.split(' ][ ').map((t, i) => <span className={`title_${t.toLowerCase().replace(/[^a-z0-9-]/g, '-')}`} key={`title${i}`}>{t}</span>)}
		</div>
		<div className={`screen-content`}>
			<div className={'teams-container'}>
				{teams.map((team, i) => <TeamCard team={team} key={`team${i}`}/>)}
			</div>
		</div>
	</Screen>
}

ScoresScreenTeam.propTypes = {
	title: PropTypes.string.isRequired,
	teams: PropTypes.array.isRequired,
	scores: PropTypes.object,
	styles: PropTypes.object
};

ScoresScreenTeam.defaultProps = {
	styles: {
		title: {},
		page: {}
	},
	scores: {}
}