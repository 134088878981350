import React, {useEffect, useRef} from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';

/** @jsxImportSource @emotion/react */

export default function ScoresScreen(props) {
	const ref = useRef(null);
	const autoScroll = props.autoScroll;
	const scrollDelay = props.scrollDelay;
	const title = props.title;
	const scores = props.scores;
	const showPos = props.showPos;
	const showTeam = props.showTeam;
	const showNewScores = props.showNewScores;
	const teams = props.teams;
	
	useEffect(() => {
		
		let timeout;
		let active = true;
		
		if (!autoScroll) {
			const el = ref && ref.current;
			if (el) {
				el.scrollTop = 0;
			}
			
		} else {
			let direction = 'down';
			const step = 1;
			const scrollDelay = props.scrollDelay * 1000;
			const changeDirectionDelay = props.changeDirectionDelay * 1000;
			
			const scroll = () => {
				if (!active) {
					return;
				}
				
				const el = ref && ref.current;
				if (el) {
					const currentScroll = Math.ceil(el.scrollTop);
					if (direction === 'down') {
						el.scrollTop = currentScroll + step;
						if (el.scrollHeight - el.scrollTop <= el.clientHeight) {
							direction = 'up';
							return setTimeout(scroll, changeDirectionDelay);
						}
					} else {
						el.scrollTop = currentScroll - step;
						if (el.scrollTop <= 0) {
							direction = 'down';
							return setTimeout(scroll, changeDirectionDelay);
						}
					}
				}
				window.requestAnimationFrame(scroll);
			}
			
			timeout = setTimeout(scroll, scrollDelay);
		}
		
		return () => {
			clearTimeout(timeout);
			active = false;
		}
	}, [autoScroll, scrollDelay]);
	
	// Calculate column sizes.
	let maxScore = 0;
	let maxTotalScore = 0;
	let maxTeamLength = 0;
	let teamIndex = {};
	scores.forEach(row => {
		maxScore = Math.max(maxScore, Math.abs(row.scores));
		maxTotalScore = Math.max(maxTotalScore, Math.abs(row.totalScores));
	});
	teams.forEach((team, i) => {
		maxTeamLength = Math.max(team.name.length, maxTeamLength);
		teamIndex[team._id] = i + 1;
	});
	
	return <Screen
		className={`scores has-scores`}
		bgColor={props.styles.page.bgColor}
		mediaBg={props.styles.page.background}
		css={{
			'.row': {
				'& .position': {
					width: `${scores.length.toString().length}ch`
				},
				'& .user-team': {
					'--team-name-length': maxTeamLength,
				},
				'& .scores': {
					width: `${maxScore.toString().length + 1}ch`
				},
				'& .total-scores': {
					width: `${maxTotalScore.toString().length}ch`
				}
			}
		}}
	>
		<div className={`screen-title`}>{title}</div>
		<div className={`screen-content`}>
			{scores.length > 0 && (
				<div className={`rows`} ref={ref}>
					{scores.map((s, i) => (
						<div key={`si${i}`} className={(showTeam && s.team) ? `row team-${teamIndex[s.team._id]}` : `row`}>
							{showPos
								? <div className={'position'}>{showPos ? (i + 1) : ''}</div>
								: <div></div>
							}
							<div className={'user-img'}><img src={s.user.profileImageUrl} alt={'profile pic'}/></div>
							<div className={`user-name`}>{s.user.displayName}</div>
							
							<div className={'total-scores'}>{s.totalScores}</div>
							
							{showNewScores
								? <div className={`scores ${s.scores < 0 ? 'penalty' : ''}`}>{s.scores > 0 ? '+' : ''}{s.scores}</div>
								: <div></div>
							}
							
							{(showTeam && s.team)
								? <div className={'user-team'} style={{
									'--team-color': s.team.color
								}}>{s.team.name}</div>
								: <div className={'user-team'}></div>
							}
						</div>
					))}
				</div>
			)}
		</div>
	</Screen>
}

ScoresScreen.propTypes = {
	autoScroll: PropTypes.bool,
	changeDirectionDelay: PropTypes.number,
	//title: PropTypes.string.isRequired,
	scores: PropTypes.array.isRequired,
	scrollDelay: PropTypes.number,
	styles: PropTypes.object,
	showPos: PropTypes.bool,
	showTeam: PropTypes.bool,
	showNewScores: PropTypes.bool,
	teams: PropTypes.array
};

ScoresScreen.defaultProps = {
	autoScroll: true,
	changeDirectionDelay: 2,
	scrollDelay: 5,
	styles: {
		title: {},
		page: {}
	},
	showPos: false,
	showTeam: false,
	showNewScores: true,
	teams: []
}