import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const outputNumber = (n, prependZero) => {
	n = Math.floor(n);
	return prependZero ? (n < 10 ? `0${n}` : n) : n;
}

export default function CountDown(props) {
	const [timeLeft, setTimeLeft] = useState(Math.max(0, props.timeLeft));
	let timeLeftInSeconds = timeLeft / 1000;
	const prependZero = props.prependZero;

	/**
	 * Tick.
	 */
	useEffect(() => {
		const int = setInterval(() => {
			setTimeLeft(prevTime => Math.max(0, prevTime - 1000));
		}, 1000);
		return () => {
			clearInterval(int);
		}
	}, []);
	
	if (props.secondsOnly) {
		return <div className={'countdown secondsOnly'}>
			{outputNumber(timeLeftInSeconds, prependZero)}
		</div>
	} else {
		let hours = Math.floor(timeLeftInSeconds / 3600);
		timeLeftInSeconds -= hours * 3600;
		
		let minutes = Math.floor(timeLeftInSeconds / 60);
		timeLeftInSeconds -= minutes * 60;
		
		let seconds = Math.floor(timeLeftInSeconds);
		
		return <div className={'countdown'}>
			{outputNumber(hours, prependZero)}:{outputNumber(minutes, prependZero)}:{outputNumber(seconds, prependZero)}
		</div>
	}
}

CountDown.propTypes = {
	timeLeft: PropTypes.number.isRequired,
	prependZero: PropTypes.bool,
	secondsOnly: PropTypes.bool
}

CountDown.defaultProps = {
	prependZero: true,
	secondsOnly: false
}