import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faFlagCheckered, faPeopleGroup, faXmark} from '@fortawesome/free-solid-svg-icons';

export default function TeamCard(props) {
	const team = props.team;
	const stats = team.stats;
	const numUsers = stats.users || 0;
	const correctAnswers = stats.correctAnswers || 0;
	const totalAnswers = stats.totalAnswers || 0;
	const totalScores = stats.totalScores || 0;
	
	return (<div className={'team-card'} style={{backgroundColor: team.color}}>
			<div className={'team-name'}>{team.name}</div>
			<div className={'team-card__img'} style={team.img ? {backgroundImage: `url("${team.img.src}")`} : null}></div>
			<div className={'team-card__content'}>
				<div className={'team-card__content_col_1'}>
					<TeamCardBar color={team.color} percent={stats.percent}/>
				</div>
				<div className={'team-card__content_col_2'}>
					<TeamStatsRow icon={faPeopleGroup} label={'Players'} value={numUsers} className={'players'}/>
					<TeamStatsRow icon={faCheck} label={'Correct Answers'} value={correctAnswers} className={'correct'}/>
					<TeamStatsRow icon={faXmark} label={'Incorrect Answers'} value={totalAnswers - correctAnswers} className={'incorrect'}/>
					<TeamStatsRow icon={faFlagCheckered} label={'Total Scores'} value={totalScores} className={'total'}/>
				</div>
			</div>
		</div>
	);
}

function TeamStatsRow(props) {
	return (
		<div className={`team-card__content_stats ${props.className || ''}`}>
			<div className={'team-card__content_stats__icon'}>
				<FontAwesomeIcon icon={props.icon}/>
			</div>
			<div className={'team-card__content_stats__label'}>
				{props.label}
			</div>
			<div className={'team-card__content_stats__value'}>{props.value}</div>
		</div>
	)
}

function TeamCardBar(props) {
	const barRef = useRef(null);
	const color = props.color;
	const percent = props.percent;
	const [displayPercent, setDisplayPercent] = useState(0);
	
	let settingBarHeight = false;
	
	useEffect(() => {
		setBarHeight();
	}, []);
	
	/**
	 * Animated number update.
	 */
	useEffect(() => {
		const dt = Math.abs(percent - displayPercent) || 1;
		const time = 1000 / dt;
		const percentInt = setInterval(() => {
			setDisplayPercent(prev => {
				if (percent > prev) {
					return prev + 1;
				} else if (percent < prev) {
					return prev - 1;
				}
				return prev;
			});
		}, time);
		
		setBarHeight();
		
		return () => clearInterval(percentInt);
	}, [percent]);
	
	const setBarHeight = () => {
		if (settingBarHeight || !barRef || !barRef.current) {
			return;
		}
		settingBarHeight = true;
		
		if (!barRef.current.style.height) {
			barRef.current.style.height = 0;
			setTimeout(() => {
				if (barRef && barRef.current) {
					barRef.current.style.height = `${percent}%`;
					settingBarHeight = false;
				}
			}, 100);
		} else {
			if (barRef && barRef.current) {
				barRef.current.style.height = `${percent}%`;
				settingBarHeight = false;
			}
		}
	}
	
	return (
		<div className={'team-card-bar'}>
			<div className={'team-card-bar__inner'} style={{borderColor: color}}>
				<div className={'team-card-bar__col'} style={{backgroundColor: color}} ref={barRef}></div>
				<div className={'team-card-bar__text'}>{displayPercent}%</div>
			</div>
		</div>
	)
}