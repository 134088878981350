import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import QuestionScreen from './poll/QuestionScreen';

export default function Poll(props) {
	
	const poll = props.poll;
	const [question, setQuestion] = useState(null);
	
	const updateQuestion = question => {
		if (question && question._id) {
			let totalVotes = 0;
			question.options.forEach(o => {
				o.votes = o.votes || 0;
				totalVotes += o.votes;
			});
			
			let totalPercent = 0;
			for (let i = 0; i < question.options.length; i++) {
				const o = question.options[i];
				let votes = o.votes;
				if (votes > 0) {
					if (i === question.options.length - 1) {
						o.percent = 100 - totalPercent;
					} else {
						const percent = Math.round(o.votes / totalVotes * 100);
						o.percent = percent;
						totalPercent += percent;
					}
				} else {
					o.percent = 0;
				}
			}
			
			setQuestion(question);
		}
	}
	
	useEffect(() => {
		
		// Set initial question.
		updateQuestion(props.question);
		
		// Listen to the changes from the socket.
		const client = io(`${poll.serverUrl}/${poll._id}`, {
			transports: ['websocket']
		});
		client.on('cmd', msg => {
			console.log('poll cmd', msg);
			updateQuestion(msg);
		});
		
		return () => {
			client.disconnect();
		}
	}, [props.question]);
	
	return question && <QuestionScreen
		quiz={props.quiz}
		question={question}
		timeLeft={props.timeLeft}
	/>;
}