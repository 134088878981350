import React from 'react';
import CountDown from '../../common-libs/components/CountDown';
import PropTypes from 'prop-types';
import Screen from '../../common-libs/quiz/components/Screen';

/** @jsxImportSource @emotion/react */

export default function QuestionScreen(props) {
	
	const quiz = props.quiz;
	const question = props.question;
	const options = question.options;
	
	return (
		<Screen
			className={`quiz-screen question poll`}
			mediaBg={null}
			css={{}}
		>
			<div className={`question-text`}>
				{question.text}
			</div>
			<div className={`countdown-wrap`}>
				{quiz.status === 'running' && <CountDown key={props.timeLeft} timeLeft={props.timeLeft} secondsOnly={true} prependZero={false}/>}
			</div>
			<div className={`options`}>
				{options.map((a, i) => <div key={i} className={`option`}>
					<div className={`option-index`}>{i + 1}</div>
					<div className={`option-text`}>{a.text}</div>
					<div className={'option-percent'}>{a.percent}%</div>
				</div>)}
			</div>
		</Screen>
	)
}

QuestionScreen.propTypes = {
	question: PropTypes.object.isRequired,
	timeLeft: PropTypes.number.isRequired
};