import React, {useEffect, useState} from 'react';
import QuestionScreen from '../common-libs/quiz/screens/QuestionScreen';
import AnswerScreen from '../common-libs/quiz/screens/AnswerScreen';
import ScoresScreenTeam from '../common-libs/quiz/screens/ScoresScreenTeam';
import Poll from '../components/Poll';
import PollResultScreen from '../components/poll/ResultScreen';

export default function GameBlock(props) {
	
	const quiz = props.quiz;
	const socket = props.socket;
	const disableScores = props.disableScores;
	
	const [state, setState] = useState({});
	
	useEffect(() => {
		socket.on('cmd', onCmd);
		return () => {
			if (socket) {
				socket.off('cmd', onCmd);
			}
			
			if (state && state.timeout) {
				clearTimeout(state.timeout);
			}
		}
	}, []);
	
	const onCmd = cmd => {
		
		if (cmd.action !== 'screen') {
			return;
		}
		
		let screen = cmd.screen;
		if (screen === 'scores' && disableScores) {
			screen = 'answer';
		} else if (screen === 'poll-question' && state.screen === screen) {
			return;
		}
		
		setState(prev => {
			const newState = {
				...prev,
				screen,
				date: cmd.date,
				status: cmd.running ? 'running' : 'paused',
				numQuestionsAsked: cmd.numQuestionsAsked,
				totalQuestions: cmd.totalQuestions
			};
			
			if (cmd.till) {
				newState.till = cmd.till;
			}
			
			switch (screen) {
				case 'question':
				case 'answer':
					newState.question = cmd.question;
					break;
				case 'scores':
					newState.teamResults = cmd.teamResults || {};
					newState.teamGlobalResults = cmd.teamGlobalResults || {};
					newState.scoresType = 'question';
					
					const now = Date.now();
					const till = +cmd.till || now;
					const timePerScreen = quiz.screens.scores.duration / 2 * 1000;
					
					if (now >= till - timePerScreen) {
						newState.scoresType = 'global';
					} else {
						newState.timeout = setTimeout(() => {
							setState(prev => {
								return {...prev, scoresType: 'global'};
							});
						}, (till - now) / 2);
					}
					break;
				case 'poll-question':
					newState.pollQuestion = cmd.pollQuestion;
					break;
				case 'poll-result':
					newState.pollResult = cmd.pollResult;
					break;
			}
			
			return newState;
		});
	}
	
	return <div className={`game-page`}>
		{state.screen === 'question' && <QuestionScreen
			quiz={{...quiz, status: state.status, numQuestionsAsked: state.numQuestionsAsked, totalQuestions: state.totalQuestions}}
			timeLeft={state.till - state.date}
			question={state.question}
		/>}
		
		{state.screen === 'answer' && <AnswerScreen
			quiz={quiz}
			question={state.question}
		/>}
		
		{(state.screen === 'scores') && <ScoresScreenTeam
			teams={quiz.teams}
			scores={state.scoresType === 'question' ? state.teamResults : state.teamGlobalResults}
			title={state.scoresType === 'question' ? 'How teams scored on last question' : 'Overall Team Scores'}
		/>}
		
		{(state.screen === 'poll-question' && quiz.poll) && <Poll
			quiz={quiz}
			poll={quiz.poll}
			question={state.pollQuestion}
			timeLeft={state.till - state.date}
		/>}
		
		{(state.screen === 'poll-result' && quiz.poll) && <PollResultScreen
			quiz={quiz}
			question={state.pollResult}
		/>}
	</div>
}
