import React from 'react';
import FinalScreen from '../common-libs/quiz/screens/FinalScreen';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import PropTypes from 'prop-types';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';

export default function FinalGameScreen(props) {
	
	const quiz = props.quiz;
	const screen = quiz.screens.final;
	const settings = screen.scoresStyles || {};
	const titleSettings = settings.title || {};
	const scores = populateTeams(props.scores, quiz.teams);
	
	return <>{screen.screenType === 'scores' ?
		<ScoresScreen
			scores={scores}
			title={titleSettings.text}
			showPos={settings.showPos}
			showTeam={settings.showTeam}
			showNewScores={false}
			scrollDelay={settings.scrollDelay}
			styles={{
				title: titleSettings,
				page: settings
			}}
		/> :
		<FinalScreen quiz={quiz}/>
	}</>
}

FinalGameScreen.propTypes = {
	quiz: PropTypes.object.isRequired,
	scores: PropTypes.array
}

FinalGameScreen.defaultProps = {
	scores: []
}