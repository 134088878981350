import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import GamePage from './pages/GamePage';
import LeaderboardPage from './pages/LeaderboardPage';
import LeaderboardTeamPage from './pages/LeaderboardTeamPage';
import SidebarPage from './pages/SidebarPage';
import RoundsLeaderboardPage from './pages/RoundsLeaderboardPage';
import TeamPlayersPage from './pages/TeamPlayersPage';
import Message from './components/Message';

import MainPage from './redbull/MainPage';

import './common-libs/style.scss';
import './common-libs/redbull.scss';
import 'animate.css'
import './App.css';
function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={'/final-leaderboard'} element={<RoundsLeaderboardPage/>}/>
					<Route path={'/:quizId'} element={<GamePage/>}/>
					<Route path={'/:quizId/leaderboard'} element={<LeaderboardPage/>}/>
					<Route path={'/:quizId/sidebar'} element={<SidebarPage/>}/>
					<Route path={'/:quizId/teams-leaderboard'} element={<LeaderboardTeamPage/>}/>
					<Route path={'/:quizId/team-players'} element={<TeamPlayersPage/>}/>
					<Route path={'/:quizId/rb-main'} element={<MainPage/>}/>
					<Route path={'*'} element={<Message msg={'Error 404'}/>}/>
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default App;
